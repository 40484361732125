// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-comments-js": () => import("./../../../src/pages/app/comments.js" /* webpackChunkName: "component---src-pages-app-comments-js" */),
  "component---src-pages-app-dashboard-js": () => import("./../../../src/pages/app/dashboard.js" /* webpackChunkName: "component---src-pages-app-dashboard-js" */),
  "component---src-pages-app-edit-comment-js": () => import("./../../../src/pages/app/edit-comment.js" /* webpackChunkName: "component---src-pages-app-edit-comment-js" */),
  "component---src-pages-app-edit-marquee-tag-js": () => import("./../../../src/pages/app/edit-marquee-tag.js" /* webpackChunkName: "component---src-pages-app-edit-marquee-tag-js" */),
  "component---src-pages-app-edit-social-js": () => import("./../../../src/pages/app/edit-social.js" /* webpackChunkName: "component---src-pages-app-edit-social-js" */),
  "component---src-pages-app-edit-tag-js": () => import("./../../../src/pages/app/edit-tag.js" /* webpackChunkName: "component---src-pages-app-edit-tag-js" */),
  "component---src-pages-app-edit-user-js": () => import("./../../../src/pages/app/edit-user.js" /* webpackChunkName: "component---src-pages-app-edit-user-js" */),
  "component---src-pages-app-edit-video-js": () => import("./../../../src/pages/app/edit-video.js" /* webpackChunkName: "component---src-pages-app-edit-video-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-login-js": () => import("./../../../src/pages/app/login.js" /* webpackChunkName: "component---src-pages-app-login-js" */),
  "component---src-pages-app-marquee-tags-js": () => import("./../../../src/pages/app/marquee-tags.js" /* webpackChunkName: "component---src-pages-app-marquee-tags-js" */),
  "component---src-pages-app-new-marquee-tag-js": () => import("./../../../src/pages/app/new-marquee-tag.js" /* webpackChunkName: "component---src-pages-app-new-marquee-tag-js" */),
  "component---src-pages-app-new-social-js": () => import("./../../../src/pages/app/new-social.js" /* webpackChunkName: "component---src-pages-app-new-social-js" */),
  "component---src-pages-app-new-tag-js": () => import("./../../../src/pages/app/new-tag.js" /* webpackChunkName: "component---src-pages-app-new-tag-js" */),
  "component---src-pages-app-new-user-js": () => import("./../../../src/pages/app/new-user.js" /* webpackChunkName: "component---src-pages-app-new-user-js" */),
  "component---src-pages-app-payout-report-js": () => import("./../../../src/pages/app/payout-report.js" /* webpackChunkName: "component---src-pages-app-payout-report-js" */),
  "component---src-pages-app-profile-js": () => import("./../../../src/pages/app/profile.js" /* webpackChunkName: "component---src-pages-app-profile-js" */),
  "component---src-pages-app-rental-histories-js": () => import("./../../../src/pages/app/rental-histories.js" /* webpackChunkName: "component---src-pages-app-rental-histories-js" */),
  "component---src-pages-app-social-post-js": () => import("./../../../src/pages/app/social-post.js" /* webpackChunkName: "component---src-pages-app-social-post-js" */),
  "component---src-pages-app-subscription-history-js": () => import("./../../../src/pages/app/subscription-history.js" /* webpackChunkName: "component---src-pages-app-subscription-history-js" */),
  "component---src-pages-app-tags-js": () => import("./../../../src/pages/app/tags.js" /* webpackChunkName: "component---src-pages-app-tags-js" */),
  "component---src-pages-app-user-confirmation-js": () => import("./../../../src/pages/app/user-confirmation.js" /* webpackChunkName: "component---src-pages-app-user-confirmation-js" */),
  "component---src-pages-app-users-js": () => import("./../../../src/pages/app/users.js" /* webpackChunkName: "component---src-pages-app-users-js" */),
  "component---src-pages-app-video-categories-js": () => import("./../../../src/pages/app/video-categories.js" /* webpackChunkName: "component---src-pages-app-video-categories-js" */),
  "component---src-pages-app-video-new-js": () => import("./../../../src/pages/app/video-new.js" /* webpackChunkName: "component---src-pages-app-video-new-js" */),
  "component---src-pages-app-video-tags-js": () => import("./../../../src/pages/app/video-tags.js" /* webpackChunkName: "component---src-pages-app-video-tags-js" */),
  "component---src-pages-app-videos-js": () => import("./../../../src/pages/app/videos.js" /* webpackChunkName: "component---src-pages-app-videos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

