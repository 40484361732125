import Amplify from "aws-amplify"
import exports from "./src/aws-exports"
Amplify.configure({ ...exports, ssr: true })

let myAppConfig = {
  aws_appsync_graphqlEndpoint: process.env.GATSBY_API_URL,
  aws_appsync_region: process.env.GATSBY_REGION,
  aws_appsync_authenticationType: "API_KEY",
}

Amplify.configure(myAppConfig)